@media only screen and (min-width: 400px) {
 .images {
     &:hover {
         transform:scale(1.5, 1.5);
         transition-duration: 100ms;
     }
 } 
}

p{
    margin:0;
    padding:1em;
}

.uncollectButton{
    border-radius: 50%;
    width:30px;
    height:30px;
    background-color:#FF5065;

    border: 1px solid #FF5065;

    float:right;

    &:hover{
        background-color:#12c0df;
        cursor: pointer;
        transition: background-color 0.2s ease;


    }
}


.collectButton {
    border-radius: 50%;
    width:30px;
    height:30px;
    border: 1px solid #FF5065;
    background-color: white;
    color:red;
    float:right;

    &:hover{
        background-color:rgb(248, 168, 177);
        cursor: pointer;
        transition: background-color 0.2s ease;


    }
    
}

.collectButton2 {
    border-radius: 50%;
    width:30px;
    height:30px;
    border: 1px solid #FF5065;
    background-color: white;
    color:red;
   

    &:hover{
        background-color:rgb(248, 168, 177);
        cursor: pointer;
        transition: background-color 0.2s ease;


    }
    
}
.uncollectButton:hover + .remove {
    display: inline-block;
    opacity: 1;
    transition:opacity 0.2s ease;
    color: grey;
    font-size: 0.7vw;
}

.remove { 
    opacity:0;
    display: none;
    float:left;

}
.collectButton:hover + .collect {
    display: inline-block;
    opacity: 1;
    transition:opacity 0.2s ease;
    color: grey;
    font-size: 0.7vw;
}
.collect { 
    opacity:0;
    display: inline-block;
    float:right;

}

.collectButton2:hover + .collect {
   
    opacity: 1;
    transition:opacity 0.2s ease;
    color: grey;
    font-size: 0.7vw;
}
.collect { 
    opacity:0;
    float:left;
    
    

}


.dis { 
    margin:0 !important;
    
}

.dropzone:hover {
    cursor: pointer;
    transform: scale(0.98);
}

body { 
    background-color: #f4f7f6;
    
}
input[type="checkbox"]
    {
    -webkit-appearance: checkbox;

    }

    .ownership {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;

        h1{
            text-align: center;
            font-size: 20px;
            margin: 3%;
        }
    }

.ownershipP {
    font-size: 20px;
    font-weight: 500;
    margin-left: 5%;
    margin-right:5%;
}
 

.ownershipP2 {
    font-size: 14px;
    color:#bababa;
    margin-left: 5%;
    margin-right:5%;
}

.tag {
    font-size: 14px;
    a {
        color: gray ;
    }
    a:hover {
        text-decoration: underline;
    }
}

.uploadbox:hover {
    opacity: 0.8;

}