@import url(https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,800);
@import url(https://fonts.googleapis.com/css?family=PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Archivo);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Logo{
    color:white;
    font-size: 22px;
    font-weight: bold;
    font-family: 'Archivo', sans-serif;
}

.Logo img {
    padding-right: 2%;
}
.navbar {
    box-shadow: none;
}

.container { 
    background-color: white;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding-left: 5%;
    padding-right: 5%;
}



.centered {
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 40%;
  /* Adjust this value to move the positioned div up and down */
  text-align: center;
  width: 60%;
  /* Set the width of the positioned div */ }

.squares {
  position: relative;
  display: inline-block;
  /* Make the width of box same as image */
  margin: 0 !important;
  width: 100%; }

.centered p {
  font-family: 'Roboto', sans-serif;
  bottom: 20px;
  right: 80px;
  color: white;
  margin: 0;
  font-size: 1.2rem;
  left: 10px; }

.right p {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  margin: 0;
  font-size: 1.2rem;
  opacity: 0.9; }

.module {
  background-color: black; }
  .module img {
    opacity: 0.5; }
  .module img:hover {
    opacity: 1;
    transition: opacity 0.3s ease; }

.posttag {
  font-size: 0.7rem;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px; }

.footer{
  
    padding: 2%;
    margin-top: 30%;
   
    background-color:#d6d6d6 !important ;
}
.bottom{ 
    border-bottom: 1px solid #111111;
    padding: 2%;
    margin-bottom: 10%;
   
}

.about p{
    font-size: 10px;
    color:#323232 !important
}

.contactheader {
    font-size: 14px;
    font-weight: bold;
    color:#323232 !important
    
}
.contact{
    padding-left: 25% !important ;
}


svg{
    margin-right: 5%;
    float: right;
}


.about2{
    font-size: 14px;
    font-weight: bold ;
    margin: 0 !important;
    
}

.about2 p {
    margin: 0 !important;
    color:#323232 !important
}
.card-image {
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 14px 0 rgba(0, 0, 0, 0.19);
}

.card-image:hover {
    transition:0.5s;
    box-shadow:none;
}

.post h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;   
    font-size: 20px;
    line-height:1.2;
}
@media only screen and (min-width: 400px) {
  .images:hover {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
    transition-duration: 100ms; } }

p {
  margin: 0;
  padding: 1em; }

.uncollectButton {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #FF5065;
  border: 1px solid #FF5065;
  float: right; }
  .uncollectButton:hover {
    background-color: #12c0df;
    cursor: pointer;
    transition: background-color 0.2s ease; }

.collectButton {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #FF5065;
  background-color: white;
  color: red;
  float: right; }
  .collectButton:hover {
    background-color: #f8a8b1;
    cursor: pointer;
    transition: background-color 0.2s ease; }

.collectButton2 {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #FF5065;
  background-color: white;
  color: red; }
  .collectButton2:hover {
    background-color: #f8a8b1;
    cursor: pointer;
    transition: background-color 0.2s ease; }

.uncollectButton:hover + .remove {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.2s ease;
  color: grey;
  font-size: 0.7vw; }

.remove {
  opacity: 0;
  display: none;
  float: left; }

.collectButton:hover + .collect {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.2s ease;
  color: grey;
  font-size: 0.7vw; }

.collect {
  opacity: 0;
  display: inline-block;
  float: right; }

.collectButton2:hover + .collect {
  opacity: 1;
  transition: opacity 0.2s ease;
  color: grey;
  font-size: 0.7vw; }

.collect {
  opacity: 0;
  float: left; }

.dis {
  margin: 0 !important; }

.dropzone:hover {
  cursor: pointer;
  -webkit-transform: scale(0.98);
          transform: scale(0.98); }

body {
  background-color: #f4f7f6; }

input[type="checkbox"] {
  -webkit-appearance: checkbox; }

.ownership {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; }
  .ownership h1 {
    text-align: center;
    font-size: 20px;
    margin: 3%; }

.ownershipP {
  font-size: 20px;
  font-weight: 500;
  margin-left: 5%;
  margin-right: 5%; }

.ownershipP2 {
  font-size: 14px;
  color: #bababa;
  margin-left: 5%;
  margin-right: 5%; }

.tag {
  font-size: 14px; }
  .tag a {
    color: gray; }
  .tag a:hover {
    text-decoration: underline; }

.uploadbox:hover {
  opacity: 0.8; }


.vizcom-header{
    font-family: 'Lato';
    font-weight: 400;
    padding:5rem;

}
.vizcom-header-mobile{
    font-family: 'Lato';
    font-weight: 400;
    padding:4rem;

}

.generatebtn {
    width: 60% !important;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #333333;
    background-color: white;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    font-size: 14px;
    outline: 0;
    height: 52px;
    line-height: 36px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.generatebtn:focus {
    background-color: none;
    outline: none;
    box-shadow: none;
}
.generatebtn:hover {
    background-color: black;
    color:white;
    box-shadow: none;
}
.updatesbtn {
    width: 92% !important;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #333333;
    background-color: white;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    font-size: 14px;
    outline: 0;
    height: 52px;
    line-height: 36px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-top: 5%;
}
.updatesbtn2 {
    width: 92% !important;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #333333;
    background-color: white;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    font-size: 2rem;
    outline: 0;
    height: 72px;
    line-height: 36px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-top: 2%;
    margin-bottom: 10%;
}

.updatesbtn2:hover {
    background-color: #000000;
    color: #FFFFFF;
    transition:.2s ease-out;


}

.tryitbtn {
    width: 22% !important;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #000000;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    font-size: 1.2rem;
    height: 52px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-top: 2%;
    margin-bottom: 10%;
    border:none;

}
.tryitbtn2 {
    width: 22% !important;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #000000;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    font-size: 1.2rem;
    height: 52px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-top: 2%;
    margin-bottom: 7%;
    border:none;

}
.learnmore {
    width: 22% !important;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #000000;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    font-size: 1.2rem;
    height: 52px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-top: 2%;
    margin-bottom: 10%;
    border:none;

}
.mobiletryitbtn {
    width: 52% !important;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #000000;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    font-size: 1.2rem;
    height: 52px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-top: 2%;
    margin-bottom: 10%;
    border:none;

}

.tryitbtn:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition:.2s ease-out;
    transition:.2s ease-in;


}



body {
    background-color: white;
}


p{
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 500;
    font-size: 0.8rem;
    text-align:center;
    
}

.pics {
    padding: 1% !important;
}

.insta {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40px;
  }
.history {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10%;
  }
.discord {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    opacity: 0.3;
  }
.vizcom {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    opacity: 0.8;
  }

  .generated-image {
      background-color: white;
      padding: 2%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width:50%;
      max-height: 500px;
  }
  .generated-image-mobile {
      background-color: white;
      padding: 2%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width:100%;
      max-height: 500px;
  }

  .animate {
    transition: -webkit-transform .35s ease-in-out;
    transition: transform .35s ease-in-out;
    transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
  }
  
  .animate.grow {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }


  .discoverimages:hover img {

    cursor: pointer;
    float:left;
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    grid-auto-rows: 200px;
}

.image-list img {
    width: 250px;
}

.discoverimages img {
  width:500px;
}

.discoverimages {
  
}

.displayedcenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 850px;
    height: auto;
}
.bg {
      
    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
  }

  .centerimage {
    margin: auto;
    width: 50%;
   
    padding: 10px;
  }


  p{
      font-family: 'Montserrat';
  }


  .centerdiv{
    
   
    
}
.profile-info {
  text-align: center; }

.iframeStyle {
  width: 75%;
  height: 500px;
  border: 0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 3%; }

.spacingLeft {
  padding-left: 3%; }

@media (max-width: 992px) {
  .iframeStyle {
    width: 100%; }
  .spacingLeft {
    padding-left: 0%; } }

.tabs {
  margin-bottom: 5%; }

.tabs .tab a {
  color: gray !important; }

.tabs .tab a:focus, .tabs .tab a:focus.active {
  outline: none; }

.profilepicture {
  max-width: 105px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; }

.editProfile {
  background-color: white;
  color: #2295ff;
  border: solid #2295ff 2px;
  width: 20px; }
  .editProfile:hover {
    background-color: #2295ff;
    color: white; }

.editBioButton {
  background-color: white;
  color: #989ca0;
  border: solid #989ca0 2px;
  width: 150px !important; }
  .editBioButton:hover {
    background-color: #989ca0;
    color: black; }

.donebutton {
  background-color: white;
  color: #2295ff;
  border: solid #2295ff 2px; }
  .donebutton:hover {
    background-color: #2295ff;
    color: white; }

.textbox {
  max-width: 50%;
  height: 150px; }

.tabs {
  background-color: transparent !important; }

.remove-button:hover {
  background-color: #d5d5d5; }

.backgroundimage {
  background-image: url(/static/media/BACKGROUND1.0708678f.jpg);
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */
  background-attachment: fixed;
  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;
  margin-bottom: 0 !important; }

.btn {
  width: 92% !important;
  display: block;
  margin: 0 auto; }

.btn2 {
  width: 100px !important;
  display: block;
  float: right;
  height: 40px;
  margin-right: 25px;
  color: black;
  background-color: white;
  border-radius: 12px; }

.btn2:hover {
  background-color: black;
  color: white; }

.image2 {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/designerspen2.appspot.com/o/image.jpg?alt=media&token=9f6f3f94-7411-4018-b2b1-093f497f22eb);
  height: 1000px;
  width: 90%; }

.signinform {
  padding: 10%;
  width: 100%; }

.input-field input[type=email], select {
  width: 92% !important;
  padding: 12px 20px;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }

.input-field input[type=text], select {
  width: 92% !important;
  padding: 12px 20px;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }

.input-field input[type=password], select {
  width: 92% !important;
  padding: 12px 20px;
  display: block;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }

.footer {
  background-color: #3a3a3a;
  margin: 0; }
  .footer h5, .footer p {
    color: white; }
  .footer h5 {
    font-size: 18px; }

.footer img {
  width: 20px;
  padding-right: 5px;
  float: left;
  padding-bottom: 50%; }

.firebaseui-card-content {
  padding: 0 !important; }

.firebaseui-container {
  max-width: 92% !important; }

.mdl-button {
  width: 100% !important;
  max-width: 100% !important; }

.firebaseui-idp-button {
  text-align: center !important; }

.firebaseui-idp-text {
  text-align: center !important; }

.box {
  margin-top: 10% !important;
  margin-bottom: 10% !important;
  padding-left: 20% !important;
  padding-right: 20% !important; }

.banner {
  padding-top: 40%; }

.motto h5 {
  text-align: center;
  padding-top: 50%;
  font-size: 1.5vw;
  font-weight: bold; }

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.credit {
  font-size: 14px;
  color: white;
  float: right;
  padding: 2%; }

.socials img {
  width: 20%;
  padding-left: 10%; }

.mobile {
  padding-top: 2%; }
  .mobile .btn {
    width: 100% !important; }
  .mobile .input-field {
    width: 100% !important; }

h2 {
  padding-left: 20px;
  padding-right: 20px;
  color: #616161; }

h1 {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 45px; }

img {
    max-width:100%;
}

h1 { 
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}
h2{
    font-size:28px;
     font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}


.Toptitle { padding-top:10%;}

video {
    max-width: 100%;
}

.mobile {
    max-width:100%;
}



.divStyle {
    min-height: 100%;
  /* Set up proportionate scaling */
  width: 100%;
  height:100%;
  max-height:100%;

}


.MdivStyle {
    min-height: 100%;
  /* Set up proportionate scaling */
  width: 100%;
  height:300px;
  max-height:100%;
  background-image: url(/static/media/mobilesigninbackground.bf39c8b7.jpg);
}
.MdivStyle h1 {
    text-align: left;
    padding-top:40%;
    color: #f3f3f3;
    font-size:12px;
    
  
  }

.divStyle h1 {
  text-align: center;
  height: 309px;
  line-height: 309px;
  padding-top:10%;
  color: white;

}

.divStyle p{
    text-align:center;
    color: white;   
}
.MdivStyle p{
    text-align:right;
    color: white;   
}

.submitbtn{
    display: flex; 
    justify-content: center;
    padding-bottom:35%;

}

.mobilesubmitbtn {
    display: flex; 
    justify-content: center;
    padding-bottom:10%;
}

.MobileSigninFont {
    font-size:16px;
}

.box {
    margin-top: 15%;
}

.signinform {
    margin-left:25%;
    margin-right:25%;
    padding: 5%;
    padding-top: 2% !important;
}


.title {
    text-align: center;
    color: #232323;
    font-family: 'Raleway', sans-serif;
   
    
}

.title h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    
}

.signinform h5 {
    color : #232323;
}

.signinform input {
    color:#232323;
}

.contenbox {
    list-style-type: inline;
 
    margin: 0;
    padding: 0;
    background: red;
  
}

.contentprojects {
    display: block;
}


.socialIcons{
    
    margin-top:15%;
}

.active {
    color:  #232323;
    text-decoration: none;
  }
