.card-image {
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 14px 0 rgba(0, 0, 0, 0.19);
}

.card-image:hover {
    transition:0.5s;
    box-shadow:none;
}

.post h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;   
    font-size: 20px;
    line-height:1.2;
}