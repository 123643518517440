

.divStyle {
    min-height: 100%;
  /* Set up proportionate scaling */
  width: 100%;
  height:100%;
  max-height:100%;

}


.MdivStyle {
    min-height: 100%;
  /* Set up proportionate scaling */
  width: 100%;
  height:300px;
  max-height:100%;
  background-image: url("../images/mobilesigninbackground.jpg");
}
.MdivStyle h1 {
    text-align: left;
    padding-top:40%;
    color: #f3f3f3;
    font-size:12px;
    
  
  }

.divStyle h1 {
  text-align: center;
  height: 309px;
  line-height: 309px;
  padding-top:10%;
  color: white;

}

.divStyle p{
    text-align:center;
    color: white;   
}
.MdivStyle p{
    text-align:right;
    color: white;   
}

.submitbtn{
    display: flex; 
    justify-content: center;
    padding-bottom:35%;

}

.mobilesubmitbtn {
    display: flex; 
    justify-content: center;
    padding-bottom:10%;
}

.MobileSigninFont {
    font-size:16px;
}

.box {
    margin-top: 15%;
}

.signinform {
    margin-left:25%;
    margin-right:25%;
    padding: 5%;
    padding-top: 2% !important;
}


.title {
    text-align: center;
    color: #232323;
    font-family: 'Raleway', sans-serif;
   
    
}

.title h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    
}

.signinform h5 {
    color : #232323;
}

.signinform input {
    color:#232323;
}

.contenbox {
    list-style-type: inline;
 
    margin: 0;
    padding: 0;
    background: red;
  
}

.contentprojects {
    display: block;
}


.socialIcons{
    
    margin-top:15%;
}

.active {
    color:  #232323;
    text-decoration: none;
  }