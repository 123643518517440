.backgroundimage {
    // background-image:url(https://firebasestorage.googleapis.com/v0/b/designerspen-95f24.appspot.com/o/BIG.jpg?alt=media&token=40d33966-0516-46d9-83eb-62b4cec35d39);
    // width: 100%;
    // background-image:url(https://firebasestorage.googleapis.com/v0/b/designerspen-95f24.appspot.com/o/SMALL.jpg?alt=media&token=0f955d0b-be99-423b-be62-fa5151efe673);
    // background-image:url(https://firebasestorage.googleapis.com/v0/b/designerspen2.appspot.com/o/BIGBIGBIG.jpg?alt=media&token=b48c95fc-6184-4756-a779-e37eea7d8700);
    background-image:url('../../assets/images/BACKGROUND1.jpg');
    /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  
  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */
  background-attachment: fixed;
  
  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;
  
 margin-bottom: 0 !important;
  
}

.btn{
    width:92% !important;
    display: block;
    margin : 0 auto;
}
.btn2{
    width:100px !important;
    display: block;
    float:right;
    height:40px;
    margin-right:25px;
    color:black;
    background-color: white;
    border-radius: 12px;
}
.btn2:hover {
    background-color: black;
    color:white;
}
.image2 {
    background-image:url(https://firebasestorage.googleapis.com/v0/b/designerspen2.appspot.com/o/image.jpg?alt=media&token=9f6f3f94-7411-4018-b2b1-093f497f22eb);
    height:1000px;
    width: 90%;
  
}

.signinform {
   
    padding:10%;
    width: 100%;
  
}


.input-field input[type=email], select {
  width:92% !important;
  padding: 12px 20px;
  display: block;
  margin : 0 auto;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}


.input-field input[type=text], select {
    width:92% !important;

    padding: 12px 20px;
    display: block;
    margin : 0 auto;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .input-field input[type=password], select {
    width:92% !important;

    padding: 12px 20px;
    display: block;
    margin : 0 auto;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .footer {
      background-color: #3a3a3a;
      margin:0 ;
      h5,p{
          color:white;
      }
      h5{
          font-size: 18px;
      }
  }

  .footer {
    
  img{
      width: 20px;
      padding-right: 5px;
      float:left;
      padding-bottom: 50%;
      
  }
  
}

  .firebaseui-card-content {
      padding: 0 !important
  }

  .firebaseui-container{
      max-width: 92% !important
  }

  .mdl-button {
      width: 100% !important;
      max-width: 100% !important;
  }
  .firebaseui-idp-button {
    text-align: center !important;
}

.firebaseui-idp-text { 
    text-align: center !important;
}
 
.box {
    margin-top:10% !important;
    margin-bottom:10% !important;
    padding-left:20% !important;
    padding-right:20% !important;
    

    
}

.banner {
    padding-top: 40%;
    
}

.motto {
    h5 {
    text-align: center;
    padding-top: 50%;
    font-size: 1.5vw;
    font-weight: bold;
    }
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.credit {
    font-size: 14px;
    color:white;
    float:right;
    padding:2%;
}

.socials img {
    width: 20%;
    padding-left: 10%;
}



.mobile  {

    padding-top: 2%;
    .btn {width: 100% !important;}
    .input-field{width: 100% !important;}
}

h2 { 
    
    padding-left: 20px;
    padding-right: 20px;
    color: #616161;
    
}

h1 {
    padding-left:  20px;
    padding-right:  20px;
    font-size: 45px;

}


.firebaseui-container { 

}