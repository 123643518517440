.footer{
  
    padding: 2%;
    margin-top: 30%;
   
    background-color:#d6d6d6 !important ;
}
.bottom{ 
    border-bottom: 1px solid #111111;
    padding: 2%;
    margin-bottom: 10%;
   
}

.about p{
    font-size: 10px;
    color:#323232 !important
}

.contactheader {
    font-size: 14px;
    font-weight: bold;
    color:#323232 !important
    
}
.contact{
    padding-left: 25% !important ;
}


svg{
    margin-right: 5%;
    float: right;
}


.about2{
    font-size: 14px;
    font-weight: bold ;
    margin: 0 !important;
    
}

.about2 p {
    margin: 0 !important;
    color:#323232 !important
}