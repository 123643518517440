.profile-info {
    text-align: center;
}

.iframeStyle{
    width: 75%;
    height: 500px;
    border: 0;
    border-radius: 4px;
    
    overflow: hidden;
    margin-bottom: 3%;
}

.spacingLeft{
    padding-left: 3%;
}

@media (max-width: 992px){
    .iframeStyle{
        width: 100%;
    }
    .spacingLeft{
        padding-left: 0%;
    }
}

.tabs {
    margin-bottom:5%;
}

.tabs .tab a{
    color: gray !important;
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
    outline: none;
  }

  .profilepicture {
      max-width: 105px;
      border-radius: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width:50%;

  }

.editProfile { 
    background-color: white;
    color: #2295ff;
    border:solid #2295ff 2px;
    width:20px;
    &:hover{
        background-color: #2295ff;
        color:white;
    }
}

.editBioButton {
    background-color: white;
    color: #989ca0;
    border:solid #989ca0 2px;
    width:150px !important;
    &:hover{
        background-color: #989ca0;
        color:black;
    }
}

.donebutton { 
    background-color: white;
    color: #2295ff;
    border:solid #2295ff 2px;

    &:hover{
        background-color: #2295ff;
        color:white;
    }

}

.textbox { 
    max-width: 50%;
    height:150px;
}

.tabs {
    background-color: transparent !important;
}

.remove-button:hover{
    background-color: #d5d5d5;
}