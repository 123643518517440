.Logo{
    color:white;
    font-size: 22px;
    font-weight: bold;
    font-family: 'Archivo', sans-serif;
}

.Logo img {
    padding-right: 2%;
}
.navbar {
    box-shadow: none;
}

.container { 
    background-color: white;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding-left: 5%;
    padding-right: 5%;
}


